<template>
  <div>
    <div class="goback flex-space-between">
      <div class="leftbox flex">
        <div class="imgbox"><img src="@/assets/page/fangz.png" alt="" /></div>
        <div class="title">企业动态</div>
        <!-- <div style="margin: 0 4px">-</div>
        <div class="series">纯色系列</div> -->
        <div style="margin: 0 4px">-</div>
        <div class="name">{{datainfo.title}}</div>
      </div>
      <div class="rightbox flex" @click="gobackclick">
        <div class="fhbox">返回</div>
        <div class="imgbox"><img src="@/assets/page/fh.png" alt="" /></div>
      </div>
    </div>
    <div style="margin: 60px 85px 0;font-size: 40px;text-align: center;">{{ datainfo.title }}</div>
    <div style="min-height: 500px;  margin: 60px 85px 0" v-html="datainfo.detail">
      
    </div>

    <div class="read">阅读量：{{ datainfo.views }}</div>
    <div class="Previous" @click="netkclick(1)" v-if="datainfo.last">上一篇：{{datainfo.last.title}}</div>
    <div class="Next" @click="netkclick(2)" v-if="datainfo.next">下一篇：{{datainfo.next.title}}</div>
    <div style="width: 100%; height: 30px; background-color: #f9f9f9"></div>
  </div>
</template>

<script>
import {trendsDetail} from '../../service/request/homepage';
export default {
  name: 'LaiyinPcEnterpriseinfo',

  data() {
    return {
      last: {},
      next: {},
      datainfo:{},
    }
  },

  mounted() {
    this.id=this.$route.query.id
    this.trendsDetail()
  },

  methods: {
    // 初始数据
    trendsDetail() {
      trendsDetail(
        {id:this.id}
      ).then(res => {
        console.log(res, '1');
        this.datainfo=res.msg
      })  
    },
    netkclick(type) {
      if (type == 1) {
        this.id = this.datainfo.last.id
        this.trendsDetail()
      } else if (type == 2) {
        this.id = this.datainfo.next.id
        this.trendsDetail()
      } 
    },
    gobackclick() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.goback {
  padding-top: 188px;
  margin: 0 85px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .leftbox {
    .imgbox {
      width: 19px;
      height: 16px;
      line-height: 10px;
      margin-right: 9px;
      img {
        width: 19px;
        height: 16px;
      }
    }
    .title,
    .series {
      font-size: 16px;
      color: #999999;
    }
    .name {
      color: #3e907c;
      font-size: 16px;
    }
  }
  .rightbox {
    .fhbox {
      font-size: 16px;
      color: #3e907c;
      margin-right: 6px;
    }
    .imgbox {
      width: 20px;
      height: 14px;
      line-height: 10px;
      img {
        width: 20px;
        height: 14px;
      }
    }
  }
}
.read {
  margin: 0 85px;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #eee;
  color: #999999;
  font-size: 16px;
}
.Previous {
  margin: 0 85px;
  margin-top: 44px;
  cursor: pointer;
  font-size: 16px;
  color: #999999;
}
.Next {
  margin: 0 85px;
  color: #333333;
  font-size: 16px;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 20px;
}
</style>
